var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { sm: "12" } },
        [
          _vm.restaurant
            ? _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-dinner" } }),
                      _c("h5", { staticClass: "d-inline ml-2" }, [
                        _c(
                          "h3",
                          { staticClass: "d-inline" },
                          [
                            _c("CBadge", { attrs: { color: "secondary" } }, [
                              _vm._v(_vm._s(_vm.restaurant.restaurant_name)),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" Linked Menu Items "),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "card-header-actions d-flex flex-gap-2",
                        },
                        [
                          _vm.restaurantId
                            ? _c(
                                "CButton",
                                {
                                  attrs: {
                                    color: "link",
                                    to: {
                                      name: "View Restaurant",
                                      params: { id: _vm.restaurantId },
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    staticClass: "align-bottom",
                                    attrs: { name: "cil-arrow-thick-left" },
                                  }),
                                  _vm._v(" Back To Restaurant "),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "CButton",
                            {
                              attrs: { color: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.$store.commit("set", [
                                    "bulkMenuUpdateShow",
                                    true,
                                  ])
                                },
                              },
                            },
                            [
                              _c("CIcon", {
                                staticClass: "align-bottom",
                                attrs: { name: "cil-layers" },
                              }),
                              _vm._v("Bulk "),
                              _c("strong", [_vm._v("Update")]),
                            ],
                            1
                          ),
                          _c(
                            "CButton",
                            {
                              attrs: {
                                color: "primary",
                                to: { name: "AddNewLinkedMenu" },
                              },
                            },
                            [
                              _c("CIcon", {
                                staticClass: "align-bottom",
                                attrs: { name: "cil-plus" },
                              }),
                              _c("strong", [_vm._v("Add New")]),
                              _vm._v(" Linked Menu "),
                            ],
                            1
                          ),
                          _c(
                            "CButton",
                            { attrs: { color: "dark", to: _vm.prevRoute } },
                            [
                              _c("CIcon", {
                                staticClass: "align-bottom",
                                attrs: { name: "cil-arrow-thick-left" },
                              }),
                              _vm._v(" Back "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("BackendTable", {
                    ref: "linkedMenuTable",
                    attrs: { usetoprice: _vm.restaurant.usetoprice },
                  }),
                  _c("BulkMenuUpdate", {
                    attrs: {
                      restaurant: {
                        id: _vm.restaurantId,
                        usetoprice: _vm.restaurant.usetoprice,
                      },
                    },
                    on: { updated: _vm.menusUpdated },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _vm.loading
                    ? _c("CSpinner", { attrs: { color: "primary" } })
                    : _c("h6", [_vm._v("Restaurant not found!")]),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }