var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "bulk-menu-update-modal",
      attrs: {
        title: "Bulk Menu Update",
        color: "white",
        size: "lg",
        show: _vm.$store.state.bulkMenuUpdateShow,
        closeOnBackdrop: false,
      },
      on: {
        "update:show": function ($event) {
          return _vm.$set(_vm.$store.state, "bulkMenuUpdateShow", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "CButton",
                {
                  attrs: { color: "link" },
                  on: {
                    click: function ($event) {
                      return _vm.$store.commit("toggle", "bulkMenuUpdateShow")
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "d-flex flex-md-row flex-column flex-gap-1" }, [
        _c(
          "div",
          { staticClass: "flex-grow-1" },
          [
            _c("v-select", {
              staticClass: "v-select-filter",
              attrs: {
                placeholder: "Please select a category..",
                options: _vm.availableCategories,
                loading: _vm.loading && !_vm.availableCategories.length,
              },
              on: { input: _vm.getMenuItems },
              model: {
                value: _vm.selectedCategory,
                callback: function ($$v) {
                  _vm.selectedCategory = $$v
                },
                expression: "selectedCategory",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "d-flex flex-column flex-gap-2 menu-items" }, [
        _vm.selectedCategory && !_vm.menuItems.length && !_vm.loading
          ? _c("strong", { staticClass: "lead text-danger" }, [
              _vm._v(" 🛈 There is no menu item in this category. "),
            ])
          : _vm.selectedCategory && _vm.menuItems.length
          ? _c("div", { staticClass: "flex-grow-1 table-responsive" }, [
              _c("table", { staticClass: "table table-striped" }, [
                _c("thead", [
                  _c("tr", [
                    _c(
                      "th",
                      { staticStyle: { "min-width": "40px", width: "40px" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectAllItems,
                              expression: "selectAllItems",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.selectAllItems)
                              ? _vm._i(_vm.selectAllItems, null) > -1
                              : _vm.selectAllItems,
                          },
                          on: {
                            click: _vm.selectAll,
                            change: function ($event) {
                              var $$a = _vm.selectAllItems,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectAllItems = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectAllItems = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectAllItems = $$c
                              }
                            },
                          },
                        }),
                      ]
                    ),
                    _c("th", [_vm._v("Menu")]),
                    _c(
                      "th",
                      { staticStyle: { "min-width": "150px", width: "180px" } },
                      [_vm._v("Delivery Price")]
                    ),
                    _vm.usetoprice
                      ? _c(
                          "th",
                          {
                            staticStyle: {
                              "min-width": "150px",
                              width: "180px",
                            },
                          },
                          [_vm._v("Takeout Price")]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.menuItems, function (menuItem) {
                    return _c("tr", { key: menuItem.id }, [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedItems,
                              expression: "selectedItems",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            value: menuItem,
                            checked: Array.isArray(_vm.selectedItems)
                              ? _vm._i(_vm.selectedItems, menuItem) > -1
                              : _vm.selectedItems,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.selectedItems,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = menuItem,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedItems = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedItems = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedItems = $$c
                              }
                            },
                          },
                        }),
                      ]),
                      _c("td", [_vm._v(_vm._s(menuItem.menu_name))]),
                      _c(
                        "td",
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("toCurrency")(menuItem.price)) +
                              " "
                          ),
                          menuItem.price != menuItem.new_price
                            ? [
                                _vm._v(" → "),
                                _c("strong", { staticClass: "text-success" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toCurrency")(menuItem.new_price)
                                    )
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm.usetoprice
                        ? _c(
                            "td",
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toCurrency")(menuItem.toPrice)
                                  ) +
                                  " "
                              ),
                              menuItem.toPrice != menuItem.new_toPrice
                                ? [
                                    _vm._v(" → "),
                                    _c(
                                      "strong",
                                      { staticClass: "text-success" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toCurrency")(
                                              menuItem.new_toPrice
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ])
                  }),
                  0
                ),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "sticky-bottom" },
          [
            _vm.selectedItems.length
              ? _c(
                  "CTabs",
                  { attrs: { variant: "tabs" } },
                  [
                    _c(
                      "CTab",
                      {
                        staticClass: "aside-tab-item",
                        attrs: { title: "Update Prices" },
                      },
                      [
                        _c(
                          "CForm",
                          {
                            staticClass:
                              "d-flex flex-column flex-gap-2 px-2 pt-2 align-items-baseline",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column flex-md-row w-100",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group w-100" },
                                  [
                                    _c("label", { staticClass: "d-block" }, [
                                      _vm._v("Updated Price:"),
                                    ]),
                                    _c("CInputRadioGroup", {
                                      attrs: {
                                        name: "updated_price",
                                        options: _vm.priceOptions,
                                        checked:
                                          _vm.$v.form.prices.updated_price
                                            .$model,
                                        custom: "",
                                        inline: "",
                                      },
                                      on: {
                                        "update:checked": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.prices.updated_price,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-group w-100",
                                    class: {
                                      "disable-item":
                                        _vm.form.prices.price_adjustment ===
                                        "fixed_price",
                                    },
                                  },
                                  [
                                    _c("label", { staticClass: "d-block" }, [
                                      _vm._v("Reference Price:"),
                                    ]),
                                    _c("CInputRadioGroup", {
                                      attrs: {
                                        name: "reference_price",
                                        options: _vm.priceOptions,
                                        checked:
                                          _vm.$v.form.prices.reference_price
                                            .$model,
                                        custom: "",
                                        inline: "",
                                      },
                                      on: {
                                        "update:checked": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.prices.reference_price,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column flex-md-row w-100",
                              },
                              [
                                _c("CSelect", {
                                  staticClass: "w-100 mr-2",
                                  attrs: {
                                    label: "Price Adjustment",
                                    placeholder: "Please select..",
                                    value:
                                      _vm.$v.form.prices.price_adjustment
                                        .$model,
                                    options: [
                                      {
                                        value: "fixed_price",
                                        label: "Fixed Price",
                                      },
                                      {
                                        value: "fixed_adjustment",
                                        label: "Fixed Adjustment",
                                      },
                                      {
                                        value: "percentage_adjustment",
                                        label: "Percentage Adjustment",
                                      },
                                    ],
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.prices.price_adjustment,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                                _c("CInput", {
                                  staticClass: "w-100",
                                  attrs: {
                                    label: "Value",
                                    placeholder: "0.00",
                                    value: _vm.$v.form.prices.value.$model,
                                    type: "number",
                                    step: "0.01",
                                    pattern: "^\\d+(?:\\.\\d{1,2})?$",
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.prices.value,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "prepend-content",
                                        fn: function () {
                                          return [
                                            _vm.form?.prices
                                              ?.price_adjustment ===
                                            "percentage_adjustment"
                                              ? _c("strong", [_vm._v("%")])
                                              : _c("CIcon", {
                                                  attrs: { name: "cil-euro" },
                                                }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3826605180
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "CButton",
                              {
                                attrs: {
                                  color: "primary",
                                  shape: "pill",
                                  disabled: _vm.submitted,
                                },
                                on: { click: _vm.updatePrices },
                              },
                              [
                                _c("CIcon", { attrs: { name: "cil-send" } }),
                                _vm._v(" Update "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading || _vm.submitted,
            expression: "loading || submitted",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }